import { mixins } from "@/plugins/mixins";
export default {
  name: "monitor-controller",
  mixins: [mixins],
  components: {},
  data() {
    return {
      logDialog: false,
      logData: [],
      // 日志data
      dataTotal: 0,
      currentPage: 1,
      pageSize: 10,
      pageSizeOptions: [10, 20, 30, 40, 50, 100],
      columns: [
      // {
      //     label: '仓库',
      //     prop: 'warehouseName',
      // },
      {
        label: '序列号',
        prop: 'serialNumber'
      }, {
        //     label: '车辆编号',
        //     prop: 'deviceCode',
        // }, {
        label: '厂家',
        prop: 'manufacturer'
      }, {
        label: '所在位置',
        prop: 'positionType'
      }, {
        label: '具体位置',
        prop: 'position'
      }, {
        label: '设备状态',
        prop: 'deviceStatusDesc'
      }, {
        label: '启用状态',
        prop: 'statusDesc'
      }],
      topButtons: [],
      linkButtons: [{
        name: '日志',
        code: 'monitorControllerLog',
        click: this.getLog,
        permissions: ['monitorControllerLog']
      }],
      searchItems: [{
        label: '序列号',
        prop: 'serialNumber'
      }, {
        prop: 'manufacturer',
        label: '厂家'
      }, {
        prop: 'positionType',
        label: '所在位置',
        type: 'select',
        itemProp: {
          label: 'label',
          value: 'value'
        },
        items: [{
          label: '充电站',
          value: 'chargingStation'
        }, {
          label: '车辆',
          value: 'vehicle'
        }, {
          label: '仓库',
          value: 'warehouse'
        }, {
          label: '运维人员',
          value: 'user'
        }]
      }, {
        prop: 'deviceStatus',
        label: '设备状态',
        type: 'select',
        items: ['正常', '检修', '报废']
      }]
    };
  },
  methods: {
    getLog(row) {
      this.$api.business.deviceOperationLog.getList({
        page: this.currentPage,
        size: this.pageSize,
        deviceId: row.id
      }).then(res => {
        this.logData = res.data.records;
        this.dataTotal = res.data.total;
        this.logDialog = true;
      });
    }
  }
};